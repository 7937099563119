import { colors } from '@chhjpackages/components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: colors.grey10,
    paddingTop: 40,
    paddingBottom: 60,

    [theme.breakpoints.up('sm')]: {
      paddingTop: 60,
    },

    [theme.breakpoints.up('md')]: {
      paddingTop: 48,
      paddingBottom: 48,
    },
  },
  container: {
    maxWidth: 'calc(2000px + 80px)',
  },
  carouselSlider: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 22px',
    },
  },
  sliderItem: {
    padding: '0 8px',
    outline: 'none',
  },
  icon: {
    fontSize: 80,
    display: 'block',
    margin: '0 auto 7px',

    [theme.breakpoints.up('sm')]: {
      marginBottom: 16,
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: 6,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 16,
    },
  },
  subTitle: {
    color: colors.black,
  },
}));
