import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

export const ReviewsIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="70" height="70" viewBox="0 0 70 70" fill="none" style={{ fill: 'none' }}>
      <path
        d="M35.9058 7.78885C36.2743 7.05181 37.3261 7.05181 37.6946 7.78885L39.5683 11.5362C39.7137 11.827 39.9914 12.029 40.3129 12.0777L44.3907 12.6955C45.2113 12.8199 45.532 13.832 44.9328 14.4062L41.9815 17.2346C41.7429 17.4632 41.6327 17.795 41.687 18.121L42.3692 22.2138C42.505 23.0291 41.6493 23.6479 40.9176 23.2634L37.2653 21.3444C36.9741 21.1914 36.6263 21.1914 36.3351 21.3444L32.6828 23.2634C31.9511 23.6478 31.0954 23.0291 31.2312 22.2138L31.9134 18.121C31.9677 17.795 31.8575 17.4632 31.6189 17.2346L28.6676 14.4062C28.0684 13.832 28.3891 12.8199 29.2097 12.6955L33.2875 12.0777C33.609 12.029 33.8867 11.827 34.0321 11.5362L35.9058 7.78885Z"
        fill="#F0CBA7"
      />
      <path
        d="M7.43269 29.8027C6.84323 29.2256 7.16683 28.2231 7.98251 28.0995L12.0873 27.4776C12.4088 27.4289 12.6865 27.2269 12.8319 26.9361L14.6904 23.2192C15.0617 22.4766 16.1238 22.4837 16.4851 23.2312L18.27 26.924C18.4139 27.2218 18.6957 27.4291 19.0227 27.4779L23.2113 28.1031C24.0283 28.225 24.3535 29.2287 23.7633 29.8066L20.7725 32.7352C20.5391 32.9637 20.432 33.2919 20.4857 33.6141L21.1634 37.6803C21.3003 38.5017 20.4322 39.1207 19.7002 38.7238L16.0728 36.7563C15.7776 36.5962 15.4217 36.595 15.1254 36.753L11.4243 38.7269C10.6865 39.1204 9.82027 38.4884 9.96986 37.6657L10.7051 33.622C10.7644 33.2955 10.6579 32.9608 10.4208 32.7286L7.43269 29.8027Z"
        fill="#F0CBA7"
      />
      <path
        d="M19.5063 63.6665C18.7687 64.0475 17.9149 63.4159 18.0634 62.5991L18.8047 58.522C18.864 58.1955 18.7576 57.8608 18.5204 57.6286L15.5593 54.7292C14.965 54.1472 15.2996 53.1363 16.1238 53.0239L20.1764 52.4712C20.5038 52.4266 20.788 52.2231 20.9357 51.9276L22.79 48.2192C23.1613 47.4766 24.2235 47.4837 24.5847 48.2312L26.3655 51.9156C26.5117 52.218 26.7999 52.4268 27.1327 52.4715L31.2697 53.0272C32.0951 53.1381 32.4312 54.1502 31.8362 54.7328L28.8721 57.6352C28.6387 57.8637 28.5316 58.1919 28.5853 58.5141L29.2686 62.6137C29.4045 63.429 28.5487 64.0477 27.817 63.6633L24.1609 61.7422C23.8718 61.5904 23.5268 61.5892 23.2367 61.7391L19.5063 63.6665Z"
        fill="#F0CBA7"
      />
      <path
        d="M55.536 62.5991C55.6845 63.4159 54.8307 64.0475 54.0931 63.6665L50.3627 61.7391C50.0726 61.5892 49.7276 61.5904 49.4386 61.7422L45.7824 63.6633C45.0507 64.0477 44.195 63.429 44.3308 62.6137L45.0141 58.5141C45.0678 58.1919 44.9607 57.8637 44.7273 57.6352L41.7632 54.7328C41.1682 54.1502 41.5044 53.1381 42.3297 53.0272L46.4667 52.4715C46.7995 52.4268 47.0877 52.218 47.2339 51.9156L49.0147 48.2312C49.376 47.4837 50.4381 47.4766 50.8094 48.2192L52.6637 51.9276C52.8114 52.2231 53.0956 52.4266 53.423 52.4712L57.4756 53.0239C58.2998 53.1363 58.6345 54.1472 58.0401 54.7292L55.079 57.6286C54.8419 57.8608 54.7354 58.1955 54.7947 58.522L55.536 62.5991Z"
        fill="#F0CBA7"
      />
      <path
        d="M65.6179 28.0995C66.4336 28.2231 66.7572 29.2256 66.1677 29.8027L63.1796 32.7286C62.9424 32.9608 62.836 33.2955 62.8953 33.622L63.6305 37.6657C63.7801 38.4884 62.9139 39.1204 62.1761 38.7269L58.475 36.753C58.1787 36.595 57.8228 36.5962 57.5276 36.7563L53.9001 38.7238C53.1682 39.1207 52.3001 38.5017 52.437 37.6803L53.1147 33.6141C53.1684 33.2919 53.0613 32.9637 52.8279 32.7352L49.8371 29.8066C49.2469 29.2287 49.5721 28.225 50.3891 28.1031L54.5777 27.4779C54.9047 27.4291 55.1865 27.2218 55.3304 26.924L57.1153 23.2312C57.4765 22.4837 58.5387 22.4766 58.91 23.2192L60.7685 26.9361C60.9139 27.2269 61.1916 27.4289 61.5131 27.4776L65.6179 28.0995Z"
        fill="#F0CBA7"
      />
      <path
        d="M34.8002 4L37.8002 10L44.4002 11L39.6002 15.6L40.7002 22.2L34.8002 19.1L28.9002 22.2L30.0002 15.6L25.2002 11L31.8002 10L34.8002 4Z"
        stroke="#EA7200"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4 26.3999L10.6 25.3999L13.6 19.3999L16.5 25.3999L23.2 26.3999L18.4 31.0999L19.5 37.6999L13.6 34.4999L7.6 37.6999L8.8 31.0999L4 26.3999Z"
        stroke="#EA7200"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.6996 62.5999L16.8996 55.9999L12.0996 51.2999L18.6996 50.3999L21.6996 44.3999L24.5996 50.3999L31.2996 51.2999L26.4996 55.9999L27.5996 62.5999L21.6996 59.4999L15.6996 62.5999Z"
        stroke="#EA7200"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M53.8998 62.5999L47.8998 59.4999L41.9998 62.5999L43.0998 55.9999L38.2998 51.2999L44.9998 50.3999L47.8998 44.3999L50.8998 50.3999L57.4998 51.2999L52.6998 55.9999L53.8998 62.5999Z"
        stroke="#EA7200"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M65.6004 26.3999L60.8004 31.0999L62.0004 37.6999L56.0004 34.4999L50.1004 37.6999L51.2004 31.0999L46.4004 26.3999L53.1004 25.3999L56.0004 19.3999L59.0004 25.3999L65.6004 26.3999Z"
        stroke="#EA7200"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M17.2002 21.2999C19.5002 18.4999 22.5002 16.2999 25.9002 14.8999Z" fill="white" />
      <path
        d="M17.2002 21.2999C19.5002 18.4999 22.5002 16.2999 25.9002 14.8999"
        stroke="#226937"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M15.1002 48.4001C13.2002 45.3001 12.0002 41.8001 11.7002 38.1001Z" fill="white" />
      <path
        d="M15.1002 48.4001C13.2002 45.3001 12.0002 41.8001 11.7002 38.1001"
        stroke="#226937"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M40.3 58.7C36.8 59.6 33.1 59.6 29.5 58.7Z" fill="white" />
      <path
        d="M40.3 58.7C36.8 59.6 33.1 59.6 29.5 58.7"
        stroke="#226937"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M57.8996 38C57.5996 41.6 56.4996 45.1 54.5996 48.3Z" fill="white" />
      <path
        d="M57.8996 38C57.5996 41.6 56.4996 45.1 54.5996 48.3"
        stroke="#226937"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M43.5996 14.8C46.8996 16.2 49.9996 18.3 52.2996 21.1Z" fill="white" />
      <path
        d="M43.5996 14.8C46.8996 16.2 49.9996 18.3 52.2996 21.1"
        stroke="#226937"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </SvgIcon>
  );
};
